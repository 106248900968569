import React from "react";
import Banner from "../containers/App/Banner";
import HeaderMenu from "../containers/App/HeaderMenu";
import Footer from "../containers/App/Footer";
import FeatureCards from "../containers/App/FeatureCards";
import AppFeatures from "../containers/App/AppFeatures";
import Pricing from "../containers/App/Pricing";
import Download from '../containers/App/Download';
import About from "../containers/App/About";
import Contact from "../containers/App/Contact";
import Faq from "../containers/App/Faq";
import GlobalStyle from "../containers/App/Common/global-styles";
import "../components/layout.css";
import SEO from "../components/seo";
import Fonts from "../containers/App/Common/fonts"
import BuyNow from "../components/BuyNow";

const App = () => (
   <div>
       <Fonts />
       <GlobalStyle />
       <BuyNow />
       <HeaderMenu/>
       <main>
            <SEO 
                title="App ReactJS Landing Template | GatsbyJS Templates | Zircon"
                description="Buy App Landing page template from Reactrepo. Template is built with react, gatsbyjs, bootstrap and styled components"
            />
            <Banner/>
            <FeatureCards/>
            <AppFeatures/>
            <About/>
            <Pricing/>
            <Download/>
            <Contact/>
            <Faq/>
        </main>
       <Footer/>
   </div>

)

export default App;
