import React from 'react';
import {FeaturesSection,FeaturesContents,FeaturesCard,FeaturesInner,CustomContainer,
FeaturesCardImg,FeaturesCardIcon,FeaturesCardHeading,FeaturesCardPara,
FeaturesCardCenter,FeaturesRow,FeaturesBtn,ContainerWrapper
} from './featurecards.style';
import {Row, Col} from '@bootstrap-styled/v4';

import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

const Features = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                appJson{
                    Features{
                        DelayConstant
                        FeaturesContents{
                            FeaturesCardIcon
                            FeaturesCardHeading
                            FeaturesCardPara
                            FeaturesBtn
                        }
                    }
                }
            }
      `);
    return (
        <FeaturesSection>
            <FeaturesInner>
            <ContainerWrapper>
                <CustomContainer>
                    <FeaturesRow>
                        <Col md="12" lg="12">
                            <FeaturesContents>
                                <Fade bottom delay={1*JSONData.appJson.Features.DelayConstant}>
                                    <Row>
                                        <Col lg="4" md="4" sm="12">
                                            <FeaturesCard>
                                                <FeaturesCardImg>
                                                    <FeaturesCardIcon src={JSONData.appJson.Features.FeaturesContents[0].FeaturesCardIcon} alt=""/>
                                                </FeaturesCardImg>
                                                <FeaturesCardHeading>{JSONData.appJson.Features.FeaturesContents[0].FeaturesCardHeading}</FeaturesCardHeading>
                                                <FeaturesCardPara>{JSONData.appJson.Features.FeaturesContents[0].FeaturesCardPara}</FeaturesCardPara>
                                                <FeaturesBtn href="#">{JSONData.appJson.Features.FeaturesContents[0].FeaturesBtn}</FeaturesBtn>
                                            </FeaturesCard>
                                        </Col>
                                        <Col lg="4" md="4" sm="12">
                                            <FeaturesCardCenter>
                                                <FeaturesCard>
                                                    <FeaturesCardImg>
                                                        <FeaturesCardIcon src={JSONData.appJson.Features.FeaturesContents[1].FeaturesCardIcon} alt=""/>
                                                    </FeaturesCardImg>
                                                    <FeaturesCardHeading>{JSONData.appJson.Features.FeaturesContents[1].FeaturesCardHeading}</FeaturesCardHeading>
                                                    <FeaturesCardPara>{JSONData.appJson.Features.FeaturesContents[1].FeaturesCardPara}</FeaturesCardPara>
                                                    <FeaturesBtn href="#">{JSONData.appJson.Features.FeaturesContents[1].FeaturesBtn}</FeaturesBtn>
                                                </FeaturesCard>
                                            </FeaturesCardCenter>
                                        </Col>
                                        <Col lg="4" md="4" sm="12">
                                            <FeaturesCard>
                                                <FeaturesCardImg>
                                                    <FeaturesCardIcon src={JSONData.appJson.Features.FeaturesContents[2].FeaturesCardIcon} alt=""/>
                                                </FeaturesCardImg>
                                                <FeaturesCardHeading>{JSONData.appJson.Features.FeaturesContents[2].FeaturesCardHeading}</FeaturesCardHeading>
                                                <FeaturesCardPara>{JSONData.appJson.Features.FeaturesContents[2].FeaturesCardPara}</FeaturesCardPara>
                                                <FeaturesBtn href="#">{JSONData.appJson.Features.FeaturesContents[2].FeaturesBtn}</FeaturesBtn>
                                            </FeaturesCard>
                                        </Col>
                                    </Row>
                                </Fade>
                            </FeaturesContents>
                        </Col>
                    </FeaturesRow>
                </CustomContainer>
                </ContainerWrapper>
            </FeaturesInner>
        </FeaturesSection>

    );
}

export default Features;