import React from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import {DownloadSection,DownloadInner,DownloadCol1,DownloadTitle,DownloadSubTitle,DownloadPara,DownloadContentBg,
    DownloadLinkWrapper,DownloadLeftCol,DownloadCol2
} from './download.style';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

const Download =()=>{
    const JSONData  = useStaticQuery(graphql`
    query{
        appJson {
            Download {
                DelayConstant
                DownloadTitle
                DownloadSubTitle
                DownloadPara
                DownloadLinkImgApple{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                DownloadLinkImgGoogle{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
                DownloadDemo{
                    childImageSharp{
                        fluid(quality: 100){
                        ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
`);
    return (
        <DownloadSection id="downloadContainer">
            <DownloadContentBg>
                <Container>
                <Row>
                    <Col md="12">
                        <DownloadInner>
                            
                            <DownloadCol1>
                                <Fade left delay={1*JSONData.appJson.Download.DelayConstant}>
                                        <DownloadTitle>
                                            {JSONData.appJson.Download.DownloadTitle}
                                        </DownloadTitle>
                                    </Fade>
                                    <Fade left delay={2*JSONData.appJson.Download.DelayConstant}>
                                        <DownloadSubTitle>
                                        {JSONData.appJson.Download.DownloadSubTitle}
                                        </DownloadSubTitle>
                                    </Fade>
                                    <Fade left delay={3*JSONData.appJson.Download.DelayConstant}>
                                        <DownloadPara>
                                        {JSONData.appJson.Download.DownloadPara}
                                        </DownloadPara>
                                    </Fade>
                               
                                <Fade left delay={1*JSONData.appJson.Download.DelayConstant}>
                                    <DownloadLinkWrapper>
                                        <DownloadLeftCol>
                                            <GatsImg 
                                                fluid={JSONData.appJson.Download.DownloadLinkImgApple.childImageSharp.fluid} 
                                                imgStyle={{
                                                    marginBottom:"0px",
                                                }}
                                                className="DownloadImage"
                                                alt=""
                                            />
                                        </DownloadLeftCol>
                                        <DownloadLeftCol>
                                            <GatsImg 
                                                fluid={JSONData.appJson.Download.DownloadLinkImgGoogle.childImageSharp.fluid} 
                                                className="DownloadImage"
                                                imgStyle={{
                                                    marginBottom:"0px",
                                                }}
                                                alt=""
                                            />
                                        </DownloadLeftCol>
                                    </DownloadLinkWrapper>
                                </Fade>
                            </DownloadCol1>

                            <DownloadCol2>
                                <Fade right delay={1*JSONData.appJson.Download.DelayConstant}>
                                    <GatsImg 
                                        fluid={JSONData.appJson.Download.DownloadDemo.childImageSharp.fluid} 
                                        className="DownloadDemo"
                                        imgStyle={{
                                            margin:"0px auto",
                                            width:"auto",
                                            right:"0px"
                                        }}
                                        alt=""
                                    />
                                </Fade>
                            </DownloadCol2>
                        </DownloadInner>
                    </Col>
                </Row>
            </Container>
            </DownloadContentBg>
        </DownloadSection>
    );
}
export default Download;