import React from 'react';
import {ProductsSection,ProductsInner,ProductsSubHeading,
    ProductsCol2,ProductsCol1,ProductsHeading,ProductsPara,
    ProductsBtn
} from './About.style';
import GatsImg from "gatsby-image";
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

const Products  = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                appJson{
                    Products{
                        DelayConstant
                        ProductsRightImg{
                            childImageSharp{
                                fluid(quality: 100){
                                ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        ProductsCol2{
                            ProductsHeading
                            ProductsSubHeading
                            ProductsPara
                            ProductsBtn
                        }
                    }
                }
            }
    `);
    return (
        <ProductsSection id="productContainer">
                <ProductsInner>
                    <ProductsCol1>
                        <Fade bottom delay={1*JSONData.appJson.Products.DelayConstant}>
                            <GatsImg 
                                fluid={JSONData.appJson.Products.ProductsRightImg.childImageSharp.fluid} 
                                className="ProductsRightImg"
                                imgStyle={{
                                    marginBottom:"0px",
                                }}
                                alt=""
                            />
                        </Fade>
                    </ProductsCol1>
                    <ProductsCol2>
                        <Fade bottom delay={1*JSONData.appJson.Products.DelayConstant}>
                            <ProductsHeading>
                            {JSONData.appJson.Products.ProductsCol2.ProductsHeading}
                            </ProductsHeading>
                            <ProductsSubHeading>
                            {JSONData.appJson.Products.ProductsCol2.ProductsSubHeading}
                            </ProductsSubHeading>
                            <ProductsPara>
                            {JSONData.appJson.Products.ProductsCol2.ProductsPara}
                            </ProductsPara>
                            <ProductsBtn href="#">
                            {JSONData.appJson.Products.ProductsCol2.ProductsBtn}
                            </ProductsBtn>
                        </Fade>
                    </ProductsCol2>
                </ProductsInner>
        </ProductsSection>
    );
}
export default Products;