import React from 'react';
import {Container,Row,Col} from '@bootstrap-styled/v4';
import {FooterSection,FooterCol,FooterTitle,FooterPara,FooterLogo,
    FbIcon,TwitterIcon,LinkedinIcon,InstaIcon,FooterInner,
    FooterUl,FooterLi,FooterMenu,FooterSocial,BottomFooterPara,BottomLink,
    BottomFooterWrapper} from './footer.style';

import ScrollSpy from 'react-scrollspy';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";



const Footer = () =>{
    const JSONData  = useStaticQuery(graphql`
    query{
        appJson {
            Footer {
                DelayConstant
                FooterCol1{
                    FooterLogo
                    FooterPara
                    FooterSocial{
                        FooterSocialImg
                    }
                }
                FooterCol2{
                    FooterTitle
                    FooterPara
                }
                FooterCol3{
                    FooterTitle
                    FooterUl{
                        FooterMenu
                    }
                }
                FooterCol4{
                    FooterTitle
                    FooterUl{
                        FooterMenu
                        Href
                    }
                }
                BottomFooterPara
                BottomFooterName
                BottomFooterLink
            }
        }
    }
`);
    return (
        <div>
        <FooterSection>
            <FooterInner>
                <Container>
                <Fade bottom delay={1*JSONData.appJson.Footer.DelayConstant}>
                    <Row>
                        <Col md="3" sm="6">
                            <FooterCol>
                                <FooterLogo src={JSONData.appJson.Footer.FooterCol1.FooterLogo} alt=""/>
                                <FooterPara>
                                {JSONData.appJson.Footer.FooterCol1.FooterPara}
                                </FooterPara>
                                <FooterSocial href="#" aria-label={"Fb Link"}>
                                    <FbIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label={"Twitter Link"}>
                                    <TwitterIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label={"Linkedin Link"}>
                                    <LinkedinIcon/>
                                </FooterSocial>
                                <FooterSocial href="#" aria-label={"Instagram Link"}>
                                    <InstaIcon/>
                                </FooterSocial>
                            </FooterCol>
                        </Col>
                        <Col md="3"  sm="6">
                            <FooterCol>
                                <FooterTitle>{JSONData.appJson.Footer.FooterCol2.FooterTitle}</FooterTitle>
                                <FooterPara>
                                {JSONData.appJson.Footer.FooterCol2.FooterPara}
                                </FooterPara>
                            </FooterCol>
                        </Col>
                        <Col md="3" sm="6">
                            <FooterCol>
                                <FooterTitle>{JSONData.appJson.Footer.FooterCol3.FooterTitle}</FooterTitle>
                                <FooterUl>
                                {
                                    JSONData.appJson.Footer.FooterCol3.FooterUl.map((item,idx) => {
                                    return <FooterLi>
                                        <FooterMenu href="#">
                                            {item.FooterMenu}
                                        </FooterMenu>
                                    </FooterLi>
                                    })
                                }
                                </FooterUl>
                            </FooterCol>
                        </Col>
                        <Col md="3" sm="6">
                            <FooterCol>
                            <FooterTitle>{JSONData.appJson.Footer.FooterCol4.FooterTitle}</FooterTitle>
                                <ScrollSpy offset={-59} items={JSONData.appJson.Footer.Items} currentClassName="is-current">
                                {
                                    JSONData.appJson.Footer.FooterCol4.FooterUl.map((item,idx) => {
                                    return <FooterLi>
                                        <AnchorLink offset={55} href={item.Href}>
                                            {item.FooterMenu}
                                        </AnchorLink>
                                    </FooterLi>
                                    })
                                }
                                </ScrollSpy>
                            </FooterCol>
                        </Col>
                    </Row>
                </Fade>
            </Container>
            </FooterInner>
        </FooterSection>
        <BottomFooterWrapper>
            <Container>
                <Fade bottom delay={2*JSONData.appJson.Footer.DelayConstant}>
                    <Row>
                        <Col md="12">
                            <BottomFooterPara>
                                {JSONData.appJson.Footer.BottomFooterPara}
                                <BottomLink rel="noreferrer" href={JSONData.appJson.Footer.BottomFooterLink} target="_blank">
                                    {JSONData.appJson.Footer.BottomFooterName}
                                </BottomLink>
                            </BottomFooterPara>
                        </Col>
                    </Row>
                </Fade>
            </Container>
        </BottomFooterWrapper>
        </div>
    );
}

export default Footer;