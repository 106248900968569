import React from 'react';
import {BannerSection,
    BannerContents,
    BannerContentLeft,
    BannerContentRight,
    BannerBtn,
    BannerPara,
    BannerH3,
    BannerH3Line2
} from './banner.style'
import {Container} from '@bootstrap-styled/v4';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";
import GatsImg from "gatsby-image";

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`
        query{
            appJson {
                Banner {
                    DelayConstant
                    BannerBtn
                    BannerH3Line1
                    BannerH3Line2
                    BannerImage{
                        childImageSharp{
                            fluid(quality: 100){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    BannerPara
                }
            }
        }
    `);
    return (
            <BannerSection id="homeContainer">
                <Container>
                        <BannerContents>
                            <BannerContentLeft>
                                <Fade bottom delay={1*JSONData.appJson.Banner.DelayConstant}>
                                    <div>
                                        <BannerH3>{JSONData.appJson.Banner.BannerH3Line1}</BannerH3>
                                        <BannerH3Line2>{JSONData.appJson.Banner.BannerH3Line2}</BannerH3Line2>
                                        <BannerPara>{JSONData.appJson.Banner.BannerPara}</BannerPara>
                                        <BannerBtn href="#">{JSONData.appJson.Banner.BannerBtn}</BannerBtn>
                                    </div>
                                </Fade>
                                </BannerContentLeft>
                                <BannerContentRight>
                                    <GatsImg 
                                        fluid={JSONData.appJson.Banner.BannerImage.childImageSharp.fluid} 
                                        className="BannerImage"
                                        imgStyle={{
                                            marginBottom:"0px",
                                        }}
                                        alt=""
                                    />
                                </BannerContentRight>
                        </BannerContents>
                </Container>
            </BannerSection>
    )
}

export default Banner;