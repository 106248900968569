import React from 'react';
import {PricingSection,PricingHeadingWrapper,PricingSubHeading,PricingInner,
    PricingHeading,PricingPlanCol,PricingPlanImg,PricingPlanHeading,PricingPlanPrice,
    PricingPlanLine,PricingPlanBtn,PricingPlanLightText,PricingPlanDarkText,PricingPlanColCenter,PricingCustomRow,
    PricingPlanBtnCenter,PricingCol
} from './pricing.style';
import {Container, Row, Col} from '@bootstrap-styled/v4';

import { useStaticQuery, graphql } from 'gatsby';
import Fade from "react-reveal/Fade";

const Pricing = () =>{
    const JSONData  = useStaticQuery(graphql`
        query{
            appJson {
                Pricing {
                    DelayConstant
                    PricingHeading
                    PricingSubHeading
                    PricingCustomRow{
                        PricingPlanHeading
                        PricingPlanImg
                        PricingPlanPrice
                        PricingPlanPriceSpan
                        PricingPlanDarkText{
                            Text
                        }
                        PricingPlanLightText{
                            Text
                        }
                        PricingPlanBtn
                    }
                }
            }
        }
    `);
    return (
        <PricingSection id="pricingContainer">
            <PricingInner>
                <Container>
                    
                    <Row>
                        <Col md="12">
                            <PricingHeadingWrapper>
                                <Fade bottom delay={1*JSONData.appJson.Pricing.DelayConstant}>
                                    <PricingHeading>
                                        {JSONData.appJson.Pricing.PricingHeading}
                                    </PricingHeading>
                                    <PricingSubHeading>
                                        {JSONData.appJson.Pricing.PricingSubHeading}
                                    </PricingSubHeading>
                                </Fade>
                            </PricingHeadingWrapper>
                        </Col>
                    </Row>

                    <Fade bottom delay={1*JSONData.appJson.Pricing.DelayConstant}>
                        <PricingCustomRow>
                            <PricingCol>
                                <PricingPlanCol index={0}>
                                    <PricingPlanImg src={JSONData.appJson.Pricing.PricingCustomRow[0].PricingPlanImg} alt=""/>
                                    <PricingPlanHeading>{JSONData.appJson.Pricing.PricingCustomRow[0].PricingPlanHeading}</PricingPlanHeading>
                                    <PricingPlanLine/>
                                    <PricingPlanPrice>{JSONData.appJson.Pricing.PricingCustomRow[0].PricingPlanPrice} / <span>{JSONData.appJson.Pricing.PricingCustomRow[0].PricingPlanPriceSpan}</span></PricingPlanPrice>
                                    { 
                                        JSONData.appJson.Pricing.PricingCustomRow[0].PricingPlanDarkText.map((itemDark,idxDark) => {
                                            return <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                                        })
                                    }
                                    { 
                                        JSONData.appJson.Pricing.PricingCustomRow[0].PricingPlanLightText.map((itemLight,idxLight) => {
                                            return <PricingPlanLightText>{itemLight.Text}</PricingPlanLightText>
                                        })
                                    }
                                    <PricingPlanBtn href="#">{JSONData.appJson.Pricing.PricingCustomRow[0].PricingPlanBtn}</PricingPlanBtn>
                                </PricingPlanCol>
                            </PricingCol>

                            <PricingCol>
                                <PricingPlanColCenter>
                                    <PricingPlanImg src={JSONData.appJson.Pricing.PricingCustomRow[1].PricingPlanImg} alt=""/>
                                    <PricingPlanHeading>{JSONData.appJson.Pricing.PricingCustomRow[1].PricingPlanHeading}</PricingPlanHeading>
                                    <PricingPlanLine/>
                                    <PricingPlanPrice>{JSONData.appJson.Pricing.PricingCustomRow[1].PricingPlanPrice} / <span>{JSONData.appJson.Pricing.PricingCustomRow[1].PricingPlanPriceSpan}</span></PricingPlanPrice>
                                    { 
                                        JSONData.appJson.Pricing.PricingCustomRow[1].PricingPlanDarkText.map((itemDark,idxDark) => {
                                            return <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                                        })
                                    }
                                    { 
                                        JSONData.appJson.Pricing.PricingCustomRow[1].PricingPlanLightText.map((itemLight,idxLight) => {
                                            return <PricingPlanLightText>{itemLight.Text}</PricingPlanLightText>
                                        })
                                    }
                                    <PricingPlanBtnCenter href="#">{JSONData.appJson.Pricing.PricingCustomRow[1].PricingPlanBtn}</PricingPlanBtnCenter>
                                </PricingPlanColCenter>
                            </PricingCol>

                            <PricingCol>
                                <PricingPlanCol index={2}>
                                    <PricingPlanImg src={JSONData.appJson.Pricing.PricingCustomRow[2].PricingPlanImg} alt=""/>
                                    <PricingPlanHeading>{JSONData.appJson.Pricing.PricingCustomRow[2].PricingPlanHeading}</PricingPlanHeading>
                                    <PricingPlanLine/>
                                    <PricingPlanPrice>{JSONData.appJson.Pricing.PricingCustomRow[2].PricingPlanPrice} / <span>{JSONData.appJson.Pricing.PricingCustomRow[2].PricingPlanPriceSpan}</span></PricingPlanPrice>
                                    { 
                                        JSONData.appJson.Pricing.PricingCustomRow[2].PricingPlanDarkText.map((itemDark,idxDark) => {
                                            return <PricingPlanDarkText>{itemDark.Text}</PricingPlanDarkText>
                                        })
                                    }
                                    { 
                                        JSONData.appJson.Pricing.PricingCustomRow[2].PricingPlanLightText.map((itemLight,idxLight) => {
                                            return <PricingPlanLightText>{itemLight.Text}</PricingPlanLightText>
                                        })
                                    }
                                    <PricingPlanBtn href="#">{JSONData.appJson.Pricing.PricingCustomRow[2].PricingPlanBtn}</PricingPlanBtn>
                                </PricingPlanCol>
                            </PricingCol>

                        </PricingCustomRow>
                    </Fade>
                </Container>
            </PricingInner>
        </PricingSection>
    );
}

export default Pricing;